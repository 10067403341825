import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function TermsOfUse() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Card>
            <Card.Body>
              <Card.Title><h2>Termos de Uso</h2></Card.Title>
              <Card.Text>
                <p><strong>1. Identificação da Entidade Operadora</strong></p>
                <p>O aplicativo "Bola na Rede" ("o Aplicativo") é operado pela Rio Haze App Studios - RHAS ("RHAS").</p>
                
                <p><strong>2. Aceitação dos Termos de Uso</strong></p>
                <p>O uso do Aplicativo por qualquer indivíduo ("Usuário") implica na total aceitação dos Termos de Uso estabelecidos neste documento.</p>

                <p><strong>3. Alteração dos Termos de Uso</strong></p>
                <p>A RHAS reserva-se o direito de alterar os Termos de Uso a qualquer momento e a seu exclusivo critério.</p>

                <p><strong>4. Elegibilidade para Uso do Aplicativo</strong></p>
                <p>O uso do Aplicativo é restrito a indivíduos com 18 anos de idade ou mais.</p>

                <p><strong>5. Direitos de Propriedade Intelectual</strong></p>
                <p>A propriedade intelectual, incluindo, mas não se limitando a, marca registrada, direitos autorais e patentes associadas ao Aplicativo, é de titularidade exclusiva da RHAS.</p>

                <p><strong>6. Uso Autorizado</strong></p>
                <p>O Aplicativo oferece uma plataforma para previsões esportivas gratuitas. Os Usuários podem selecionar jogos esportivos disponíveis e fazer suas previsões sem qualquer custo. Não há limites para a quantidade de previsões que um Usuário pode fazer para um jogo, incluindo a repetição do mesmo palpite.</p>

                <p><strong>7. Restrições de Uso</strong></p>
                <p>O Aplicativo <strong>não</strong> é uma plataforma de apostas monetárias. Os usuários <strong>não</strong> podem fazer apostas monetárias nem dependem do resultado das previsões de outros Usuários para obter uma vantagem. Não há cobrança financeira para o uso do Aplicativo.</p>

                <p><strong>8. Política de Privacidade</strong></p>
                <p>Durante o registro, os Usuários têm a opção de fornecer informações pessoais, incluindo, mas não se limitando a, nome, sobrenome, e-mail, CPF, número de celular, Pix e estado de residência. A provisão dessas informações é necessária para se qualificar para prêmios. Consulte nossa Política de Privacidade para mais informações sobre como essas informações são gerenciadas.</p>

                <p><strong>9. Limitação de Responsabilidade</strong></p>
                <p>A RHAS não será responsável por quaisquer danos ou prejuízos resultantes do uso do Aplicativo.</p>

                <p><strong>10. Indenização</strong></p>
                <p>Os Usuários concordam em indenizar e manter a RHAS isenta de qualquer reclamação ou demanda, incluindo honorários advocatícios razoáveis, feitos por terceiros devido ao uso indevido do Aplicativo.</p>

                <p><strong>11. Resolução de Disputas</strong></p>
                <p>Qualquer disputa decorrente do uso do Aplicativo será resolvida de acordo com a lei brasileira.</p>

                <p><strong>12. Rescisão</strong></p>
                <p>A RHAS se reserva o direito de encerrar o acesso de um Usuário ao Aplicativo sob determinadas condições, a seu exclusivo critério.</p>

                <p><strong>13. Premiações</strong></p>
                <p>As premiações variam de acordo com a importância da partida e são pagas por meio de transferência via Pix em até 5 dias úteis após a finalização da partida...</p>

                <p><strong>14. Veracidade das Informações do Usuário</strong></p>
                <p>14.1 O usuário compreende e concorda que é integralmente responsável pela precisão e veracidade das informações fornecidas, incluindo, mas não se limitando a, os dados pessoais e as informações de conta Pix utilizadas para o recebimento de prêmios.</p>

                {/* O texto restante foi omitido por questões de espaço */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}


