import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';


import './Features.css';
import { ASSETS } from '../assets/assets';

const Features = () => {
  return (
    <Container fluid className="bg-light py-5">
      <Container>
      <h1 className="text-center mb-4" style={{color: '#4caf50'}}>Conheça o App</h1>
        <br />
        <Row>
          <Col className="text-center">
            <Carousel interval={3000} fade={true}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.match_screen}
                  alt="Partidas"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Partidas</h4>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>   

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.guess_screen}
                  alt="Palpites"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Palpites</h4>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item> 

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.history_screen}
                  alt="Palpites"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Histórico</h4>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item> 

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.review_screen}
                  alt="Reviews"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Reviews</h4>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>                                        
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Features;
