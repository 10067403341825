import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';

// Importando os logos
import playStoreLogo from '../assets/images/playstore-logo.png';
import appStoreLogo from '../assets/images/appstore-logo.png';

const DownloadApp = () => {
  return (
    <Container fluid style={{background: '#EEE', padding: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Row>
        <Col className="text-center">
          <h1 style={{color: '#4caf50'}}>Baixe o Bola na Rede App!</h1>
          <br />
          <br />
          <p>
            O Bola na Rede App já está disponível para Android na Play Store! Em breve, também estaremos na App Store. Sua chance de transformar palpites em prêmios está a apenas um toque de distância!
          </p>
        </Col>
      </Row>
      <br />
      <Row className="align-items-center" style={{marginTop: '22px', marginBottom: '22px'}}>
      <Col xs={12} md={6} className="text-center" >
        <a href="https://play.google.com/store/apps/details?id=com.rhas.bola_na_rede_fed">
          <Image src={playStoreLogo} width="200" />
        </a>
      </Col>

        <Col xs={12} md={6} className="text-center">
          <a onClick={() => alert('Em breve!')}>
            <Image src={appStoreLogo} width="200" />
          </a> 
        </Col>
      </Row>
    </Container>
  );
};

export default DownloadApp;
