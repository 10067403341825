import React from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import { ASSETS } from "../assets/assets";
import "./HomePage.css";

export default function HomePage() {
  return (
    <Container className="home-page">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <div className="logo-section">
                <img
                  src={ASSETS.logo_ball}
                  alt="Bola na Rede logo"
                  className="img-fluid"
                  width={100}
                />
              </div>
              <br />
              <Card.Title className="custom-title">
                <h1>Bem-vindo ao Bola na Rede!</h1>
              </Card.Title>
              <br />
              <Card.Text>
                <Alert variant="success" className="custom-alert">
                  <p>
                    No <b>Bola na Rede</b>, você pode transformar seus palpites
                    em <b>prêmios em dinheiro</b> 💸! Faça sua jogada, arrisque
                    suas previsões e prepare-se para a emoção,{" "}
                    <b>sem gastar nada por isso</b>. O próximo vencedor pode ser
                    você 🏆!
                  </p>
                </Alert>
                <Alert variant="success" className="custom-alert">
                  <p>
                    Fique por dentro dos próximos jogos na nossa lista e não
                    deixe de dar seus palpites 📆. Lembre-se,{" "}
                    <b>
                      quanto mais você palpita, maiores são suas chances de
                      ganhar
                    </b>{" "}
                    💰!
                  </p>
                </Alert>
                <Alert variant="success" className="custom-alert">
                  <p>
                    As premiações aqui podem ser de dois tipos:{" "}
                    <b>sorte ou mérito. </b>
                    Acertou o resultado e foi sorteado? Parabéns, o prêmio é
                    seu! Já na premiação por mérito, quem acerta o palpite
                    vencedor mais vezes leva o prêmio 🌟.
                  </p>
                </Alert>
                <Alert variant="success" className="custom-alert">
                <p>
                      O pagamento dos prêmios é <b>automático via Pix</b> 💼.
                      Fique atento aos seus dados e não perca o prêmio!
                      Acompanhe nosso Histórico de Partidas e as análises para
                      turbinar seus palpites 📊. Vamos nessa, é hora da bola
                      rolar ⚽!
                    </p>
                </Alert>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
