import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Features from './components/Features';
import Download from './components/Download';
import ExclusaoConta from './components/ExclusaoConta';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import TermsOfUse from './components/TermsOfUse';


const App = () => {
  return (
    <Router>
      <Header />
      <div className="app-content">
        <Routes>
          <Route path="/" element={<><HomePage /><Features /><Download /></>} />
          <Route path="/download" element={<Download />} />
          <Route path="/privacidade" element={<PrivacyPolicy />} />
          <Route path="/termos-de-uso" element={<TermsOfUse />} />
          <Route path="/exclusao-conta" element={<ExclusaoConta />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};


ReactDOM.render(<App />, document.getElementById('root'));
