export const ASSETS = {
    logo_ball: 'https://cdn.discordapp.com/attachments/1115393667008569394/1115394375988551700/logo_1.png',
    logo_ball_rigth_green: 'https://cdn.discordapp.com/attachments/1115393667008569394/1116427250347282612/BolaNaRede_LOGO_mod_Android_icon.png',
    logo_ball_name: 'https://media.discordapp.net/attachments/1115393667008569394/1115394375720120440/Logo_Text_Bola_na_rede.png',
    logo_name: 'https://cdn.discordapp.com/attachments/1115393667008569394/1115394375443292200/TextBolaNaRede.png',

    match_screen: 'https://cdn.discordapp.com/attachments/1123787500176027789/1123787611341865020/Tela_Partidas.jpeg',
    guess_screen: 'https://cdn.discordapp.com/attachments/1123787500176027789/1123787610989547621/Tela_Palpite.png',
    review_screen: 'https://cdn.discordapp.com/attachments/1123787500176027789/1123787611727736882/Tela_Review.jpeg',
    history_screen: 'https://cdn.discordapp.com/attachments/1123787500176027789/1123995388152643584/Tela_Historico.jpeg',


}