import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { ASSETS } from '../assets/assets';

const Header = () => {
  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <Navbar
      style={{
        backgroundColor: '#4caf50',
      }}
      variant="dark"
      expand="lg"
    >
      <Container>
        <Navbar.Brand onClick={handleClick}>
          <img
            src={ASSETS.logo_ball_rigth_green}
            alt="Bola na Rede"
            style={{
              borderRadius: '50%',
              marginRight: '10px',
              cursor: 'pointer',
              background: '#fff',
            }}
            width="40"
            height="40"
          />
          Bola na Rede
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
