import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form, Toast } from "react-bootstrap";

export default function ExclusaoConta() {
  const emailTemplate = `Olá, equipe Bola na Rede,

    Gostaria de solicitar a exclusão definitiva da minha conta e de todos os dados associados a ela.

    Atenciosamente,
    [Seu Nome]
  `;

  const textareaRef = useRef(null);

  const [showToast, setShowToast] = useState(false); // Estado para controlar o Toast

  const handleCopyToClipboard = () => {
    textareaRef.current.select();
    document.execCommand("copy");
    setShowToast(true); // Mostra o Toast após copiar
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <br />
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title className="custom-title">
                  <h1>Exclusão de conta</h1>
                </Card.Title>
                <br />
                <Card.Text>
                  Se você deseja excluir sua conta do Bola na Rede, por favor,
                  envie um e-mail para
                  <a href="mailto:contato@bolanarede.app">
                    {" "}
                    contato@bolanarede.app
                  </a>
                  &nbsp;solicitando a exclusão definitiva dos seus dados. O prazo para
                  exclusão é de até 5 dias úteis após a solicitação.
                </Card.Text>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Exemplo de e-mail:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    defaultValue={emailTemplate}
                    ref={textareaRef}
                  />
                </Form.Group>
                <br />
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleCopyToClipboard}
                >
                  Copiar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Aqui está o Toast */}
      <Toast 
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999
        }}
        onClose={() => setShowToast(false)} 
        show={showToast} 
        delay={3000} 
        autohide
      >
        <Toast.Body>Texto copiado com sucesso!</Toast.Body>
      </Toast>
    </>
  );
}
