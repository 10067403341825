import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Container fluid className="bg-dark text-white py-5">
      <Container>
        <Row>
          <Col md={4}>
            <h3 className="mb-3">Bola na Rede</h3>
            <p>
              A ponte entre você e prêmios com Pix. Faça palpites ilimitados e aumente suas chances de ganhar com o aplicativo Bola na Rede.
            </p>
          </Col>
          <Col md={4} className="text-center">
            <h3 className="mb-3">Links úteis</h3>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/privacidade" className="text-white">
                  Política de privacidade
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/termos-de-uso" className="text-white">
                  Termos de uso
                </Link>
              </li>
              <li>
                <Link to="/exclusao-conta" className="text-white">
                  Solicitar exclusão de conta
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="text-end">
            <h3 className="mb-3">Contate-nos</h3>
            <p>Email: contato@bolanarede.app</p>
          </Col>
        </Row>
        <hr className="bg-white my-4" />
        <Row>
          <Col className="text-center">
            <p className="mb-0">&copy; {new Date().getFullYear()} Bola na Rede. Todos os direitos reservados.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
