import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Card>
            <Card.Body>
              <Card.Title><h2>Política de Privacidade do Bola na Rede</h2></Card.Title>
              <br />
              <Card.Text>
                <h4>INTRODUÇÃO</h4>
                <p>
                  A Rio Haze App Studios (RHAS) respeita sua privacidade e se compromete em proteger seus dados pessoais. Esta política de privacidade informa como coletamos, usamos, protegemos e divulgamos suas informações pessoais quando você usa o aplicativo Bola na Rede.
                </p>

                <h4>DADOS QUE COLETAMOS</h4>
                <p>
                  Coletamos, opcionalmente, os seguintes dados: nome, sobrenome, Unidade Federativa de residência, e-mail, celular e código Pix. Esses dados são necessários para você participar dos prêmios oferecidos pelo aplicativo.
                </p>

                <h4>COMO UTILIZAMOS SEUS DADOS</h4>
                <p>
                  Usamos seus dados com o propósito de realizar as competições e premiações oferecidas pelo Bola na Rede. Nunca vendemos ou compartilhamos seus dados com terceiros para fins de marketing.
                </p>

                <h4>SEGURANÇA DOS SEUS DADOS</h4>
                <p>
                  Tomamos todas as medidas de segurança adequadas para proteger seus dados contra acessos não autorizados, alterações, divulgação ou destruição. Os dados são armazenados e processados de maneira segura e confidencial.
                </p>

                <h4>DIREITOS DO USUÁRIO</h4>
                <p>
                  Em conformidade com a Lei Geral de Proteção de Dados (LGPD), você tem direito a acesso, retificação, exclusão, limitação de tratamento, portabilidade dos dados, e oposição. Para exercer estes direitos, por favor, entre em contato conosco.
                </p>

                <h4>ALTERAÇÕES À POLÍTICA DE PRIVACIDADE</h4>
                <p>
                  Podemos atualizar nossa política de privacidade de tempos em tempos. Qualquer alteração será publicada nesta página.
                </p>

                <h4>ISENÇÃO DE RESPONSABILIDADE</h4>
                <p>
                  A RHAS não se responsabiliza por qualquer problema decorrente do uso indevido dos dados coletados ou da utilização do aplicativo.
                </p>

                <h4>CONTATO</h4>
                <p>
                  Para perguntas ou solicitações relacionadas à sua privacidade e aos seus direitos, por favor, entre em contato conosco através do e-mail: contato@bolanarede.app
                </p>

                <p>
                  Esta política de privacidade é efetiva a partir de julho/2023. Agradecemos por confiar em nós e usar o Bola na Rede!
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}